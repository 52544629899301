.partnerItemLogo {
  position: relative;
  max-height: 75px;
  max-width: 150px;
  width: auto;

  flex: 0 0 23%;

  img, svg {
    object-fit: contain;
  }
}