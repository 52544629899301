.pageWithInfobar {
  // --header-height: calc(72px + 32px);
  --infobar-height: 32px;
}

.layoutPopin {
  @screen lg {
    min-height: 260px;
  }
}

.layoutPopinImage {
  @apply flex-1 w-full;
  height: 220px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: theme('colors.text.h1');
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @screen md {
    height: 300px;
  }

  @screen lg {
    height: 340px;
  }
  @screen xl {
    height: 400px;
  }
}

.mobileRespBtn{
  margin-top: -20px;
  margin-right: -15px;
}

.mobileResp{
  padding-top: 60px;
}