.footerLogo {
  width: 110px;

  @screen lg {
    width: 140px;
  }
}

.footerGroup {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
  }
}